<template>
  <div>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="space-between"
        vs-align="center"
        vs-w="12"
      >
        <h3>
          {{ $t("Feedback") }}
        </h3>
        <vs-tooltip
          :text="
            hasDownloadBlocked
              ? 'Função indisponível para esta conta'
              : 'O arquivo XLSX terá somente os feedbacks dos 3 últimos meses'
          "
        >
          <vs-button
            icon-pack="feather"
            icon="icon-download"
            @click="downloadCSV()"
            :disabled="hasDownloadBlocked"
          >
            {{ $t("DownloadXLSX") }}
          </vs-button>
        </vs-tooltip>
      </vs-col>
    </vs-row>
    <div class="algolia-header mb-4">
      <div class="flex md:items-end items-center justify-between flex-wrap">
        <!-- TOGGLE SIDEBAR BUTTON -->
        <vs-button
          class="lg:hidden"
          size="small"
          @click.stop="toggleFilterSidebar"
          type="filled"
          icon="star"
          >{{ $t("Filter") }}</vs-button
        >
      </div>
    </div>

    <div
      id="algolia-content-container"
      class="relative clearfix"
      style="min-height: 1200px"
    >
      <vs-sidebar
        class="items-no-padding vs-sidebar-rounded background-absolute mt-8"
        parent="#algolia-content-container"
        :click-not-close="clickNotClose"
        :hidden-background="clickNotClose"
        v-model="isFilterSidebarActive"
      >
        <div class="px-6 pt-6 pb-6">
          <div v-if="categoryString === 'NPS' || categoryString === ''">
            <h6 class="font-bold mb-3">NPS</h6>
            <div class="flex justify-center items-center relative">
              <span
                class="font-semibold text-2xl absolute top-16"
                :class="{
                  'text-success': npsScore >= 50,
                  'text-danger': npsScore < 0,
                }"
              >
                {{ npsScore }}
              </span>
              <vue-apex-charts
                ref="npsChart"
                type="donut"
                width="243"
                :options="NPSChart.chartOptions"
                :series="NPSChart.series"
              >
              </vue-apex-charts>
            </div>
          </div>

          <vs-divider v-if="categoryString === ''" />

          <div v-if="categoryString === 'CSAT' || categoryString === ''">
            <h6 class="font-bold mb-3 mt-3">CSAT</h6>
            <div class="flex justify-center items-center relative">
              <span
                class="font-semibold text-2xl absolute top-16"
                :class="{ 'text-success': csatScore >= 70 }"
              >
                {{ csatScore }}
              </span>
              <vue-apex-charts
                ref="csatChart"
                type="donut"
                width="243"
                :options="CSATChart.chartOptions"
                :series="CSATChart.series"
              >
              </vue-apex-charts>
            </div>
          </div>
        </div>
        <div class="p-6 filter-container">
          <h5 class="font-bold mb-5">{{ $t("Filters") }}</h5>
          <!-- CATEGORIES -->
          <h6 class="font-bold mb-4">{{ $t("TypeOfResearch") }}</h6>
          <div class="flex w-full">
            <vs-select
              class="no-icon-border cursor-pointer w-full"
              placeholder="Selecione"
              v-model="categoryString"
              size="small"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in categories"
                class="w-full"
              />
            </vs-select>
          </div>

          <div class="mt-3" v-if="categoryString === 'NPS'">
            <div class="flex w-full">
              <vs-select
                class="no-icon-border cursor-pointer w-full"
                placeholder="Selecione"
                v-model="npsString"
                size="small"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in npsCategories"
                  class="w-full"
                />
              </vs-select>
            </div>
          </div>
          <div class="mt-3" v-if="categoryString === 'CSAT'">
            <div class="flex w-full">
              <vs-select
                class="no-icon-border cursor-pointer w-full"
                placeholder="Selecione"
                v-model="csatString"
                size="small"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in csatCategories"
                  class="w-full"
                />
              </vs-select>
            </div>
          </div>

          <vs-divider />

          <h6 class="font-bold mb-3">Nome do contato</h6>
          <vs-input
            class="w-full"
            :placeholder="$t('SearchName')"
            v-model="searchStringLocal"
            @keyup="filterSurveys"
            icon="icon-search"
            icon-pack="feather"
            icon-after="true"
            id="searchInput"
          />

          <vs-divider />
          <!-- MULTI RANGE -->
          <h6 class="font-bold mb-3">{{ $t("Date") }}</h6>
          <div class="flex w-full">
            <vs-select
              icon="icon-calendar"
              icon-pack="feather"
              class="no-icon-border cursor-pointer w-full"
              placeholder="Filtre por data"
              v-model="dateFilter"
              size="small"
            >
              <vs-select-item
                :key="index"
                :value="item === 'Todos' ? null : index"
                :text="item"
                v-for="(item, index) in filterDateNames"
                class="w-full"
              />
            </vs-select>
          </div>

          <vs-divider />

          <!-- <date-picker v-model="time1" range></date-picker> -->
          <h6 class="font-bold mb-3">{{ $t("CustomDate") }}</h6>
          <date-picker v-model="dateFilterRange" range></date-picker>

          <vs-divider />

          <h6 class="font-bold mb-3">{{ $t("Chatbots") }}</h6>
          <div class="flex w-full">
            <vs-select
              class="no-icon-border cursor-pointer w-full"
              placeholder="Filtre por chats"
              v-model="chatsFilter"
              size="small"
              multiple
            >
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.label"
                v-for="(item, index) in chatbotData"
                class="w-full"
              />
            </vs-select>
          </div>

          <vs-divider />

          <!-- <h6 class="font-bold mb-3">{{ $t("Tags") }}</h6>
          <div class="flex w-full">
            <vs-select
              class="no-icon-border cursor-pointer w-full"
              placeholder="Filtre por tags"
              v-model="tagsFilter"
              size="small"
              multiple
              >
              <vs-select-item :key="index" :value="item" :text="item" v-for="(item,index) in tagsArray" class="w-full" />
            </vs-select>
          </div>

          <vs-divider /> -->

          <h6 class="font-bold mb-3">{{ $t("Intention") }}</h6>
          <div class="flex w-full">
            <vs-select
              class="no-icon-border cursor-pointer w-full"
              placeholder="Selecione"
              v-model="intentionString"
              size="small"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="formatIntentionName(item)"
                v-for="(item, index) in intentionsArray"
                class="w-full"
              />
            </vs-select>
          </div>

          <vs-divider />

          <h6 class="font-bold mb-3">{{ $t("Operators") }}</h6>
          <div class="flex w-full">
            <vs-select
              class="no-icon-border cursor-pointer w-full"
              placeholder="Selecione"
              v-model="operatorString"
              size="small"
            >
              <!-- Get operator_id para o filtro -->
              <vs-select-item
                :key="index"
                :value="item.operatorData._id"
                :text="item.name"
                v-for="(item, index) in operatorsArray"
                class="w-full"
              />
            </vs-select>
          </div>

          <!-- <div class="p-4">
          <h6 class="font-bold mb-3">{{ $t("Department") }}</h6>
            <div class="flex flex-wrap mt-6">
              <div class="w-full flex mb-3">
                <vs-switch v-model="selectAllIntentions" />
                <label
                  class="text-md opacity-85 ml-2"
                  style="white-space: nowrap"
                  >{{ $t("SelectAll") }}</label
                >
              </div>
              <div
                class="w-full flex mb-3"
                v-for="(intention, index) in intentionsArray"
                v-bind:key="index"
              >
                <vs-switch
                  v-model="intentionsArrayFiltered"
                  :vs-value="intention"
                />
                <label
                  class="text-md opacity-85 ml-2"
                  style="white-space: nowrap"
                  >{{ formatIntentionName(intention) }}
                </label>
              </div>
            </div>
          </div>
           <vs-divider /> -->

          <!-- <div class="p-4">
          <h6 class="font-bold mb-3">Operadores</h6>
            <div class="flex flex-wrap mt-6">
              <div class="w-full flex mb-3">
                <vs-switch v-model="selectAllOperators" />
                <label
                  class="text-md opacity-85 ml-2"
                  style="white-space: nowrap"
                  >{{ $t("SelectAll") }}</label
                >
              </div>
              <div
                class="w-full flex mb-3"
                v-for="(operator, index) in operatorsArray"
                v-bind:key="index"
              >
                <vs-switch
                  v-model="operatorsArrayFiltered"
                  :vs-value="operator._id"
                />
                <label
                  class="text-md opacity-85 ml-2"
                  style="white-space: nowrap"
                  >{{ operator.name }}
                </label>
              </div>
            </div>
          </div> -->
        </div>
      </vs-sidebar>

      <!-- RIGHT COL -->
      <div :class="{ 'sidebar-spacer-with-margin': clickNotClose }">
        <!-- SEARCH BAR -->
        <div class="relative mb-8">
          <!-- SEARCH INPUT -->

          <!-- SEARCH LOADING -->
          <div
            :hidden="!isSearchStalled"
            slot="submit-icon"
            class="absolute top-0 right-0 py-4 px-6"
          >
            <svg
              class="animate-spin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" />
            </svg>
          </div>

          <!-- CLEAR INPUT ICON -->
          <div
            slot="reset-icon"
            class="absolute top-0 right-0 py-4 px-6"
            v-show="!isSearchStalled && searchString.length > 0"
          >
            <feather-icon
              icon="XIcon"
              svgClasses="h-6 w-6 cursor-pointer"
              @click="search(null)"
            />
          </div>
        </div>

        <!-- SEARCH RESULT -->
        <!-- GRID VIEW -->
        <template>
          <div
            class="flex flex-col w-full items-center justify-center"
            v-if="nbApps === 0"
          >
            {{ $t("NoRecordsFounded") }}
            <div class="flex flex-row" v-if="noRecordsAutoMessageSurvey">
              <p>
                {{ $t("EnableSurveySending") }}
              </p>
              <a
                class="pl-1"
                :href="`/settings/autoMessages?acc=${currentAccId}`"
              >
                {{ $t("ConfigurationPage") }}
              </a>
              .
            </div>
          </div>
          <div v-if="accHasAIFeature">
            <div
              class="createSummary p-6 flex items-center justify-between rounded-md mb-5"
              v-if="!gptResumeFeedback"
            >
              <p class="text-xl text-black">
                {{ $t("GeneratingQuickSummary") }}
              </p>
              <vs-button
                type="border"
                color="rgba(255, 210, 0, 1)"
                text-color="black"
                class="px-2"
                @click="generateGptResumeFeedback"
              >
                <div class="flex items-center">
                  <ThunderIcon class="mr-2" />
                  {{ $t("GenerateSummary") }}
                </div>
              </vs-button>
            </div>
            <vs-row
              v-if="gptResumeFeedback"
              class="summaryBox p-6 rounded-md mb-5"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="space-between"
              >
                <div
                  class="tag-summary flex items-center float-left rounded-xxl py-2 px-3 text-black"
                >
                  <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4 mr-2" />
                  {{ $t("SummaryOfResearch") }}
                </div>
                <vs-button
                  type="flat"
                  color="dark"
                  icon-pack="feather"
                  icon="icon-refresh-ccw"
                  class="px-2"
                  @click="generateGptResumeFeedback"
                >
                  {{ $t("RedoSummary") }}
                </vs-button>
              </vs-col>
              <vs-col vs-w="12" class="mt-6">
                <div
                  class="text-base text-black leading-tight break-words w-full whitespace-pre-line"
                  v-html="treatMessageText(gptResumeFeedback)"
                ></div>
              </vs-col>
              <vs-col vs-w="12" class="mt-6" vs-type="flex" vs-justify="end">
                <span class="text-grey text-sm"
                  >Análise dos últimos 90 dias</span
                >
              </vs-col>
            </vs-row>
          </div>
          <item-grid-view
            v-for="(item, l) in apps"
            :key="l"
            :item="item"
            @seeOnInbox="sendToInbox(item.conversationId)"
          />
        </template>
      </div>
    </div>

    <div class="vx-row w-full" v-show="nbApps > 0">
      <vs-pagination
        :total="nbPages"
        v-model="currentPage"
        @click="currentPage++"
        style="width: 100%; justify-content: flex-end"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import apexChatData from "@/views/charts-and-maps/charts/apex-charts/apexChartData.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import ItemGridView from "./components/ItemGridView.vue";
import ThunderIcon from "@/components/svg/ThunderIcon.vue";
import xlsx from "xlsx/dist/xlsx.full.min";

export default {
  components: {
    ThunderIcon,
    ItemGridView,
    VueApexCharts,
    DatePicker,
  },
  data() {
    return {
      intentionString: null,
      operatorString: null,
      csatString: "Todos",
      npsString: "Todos",
      csatCategories: ["Todos", "Satisfeito", "Neutro", "Insatisfeito"],
      npsCategories: ["Todos", "Promotor", "Neutro", "Detrator"],
      apexChatData: apexChatData,
      apps: [],
      tags: [],
      categories: [],
      nbTotalApps: 10,
      nbPages: 0,
      nbApps: 0,
      limit: 10,
      currentPage: 0,
      searchString: "",
      searchStringLocal: "",
      timerSearchString: "",
      categoryString: "",
      tagsString: "",
      isSearchStalled: false,
      installedOnly: false,
      popupActive: false,
      installInputs: [],
      installSelects: [],
      currentlItem: null,
      installValues: { params: {} },
      chatbotData: [],
      isEdit: false,
      toDelete: {},
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      windowWidth: window.innerWidth,
      filterDateNames: this.$t("FilterDates"),
      dateFilter: null,
      dateFilterRange: null,
      tagsFilter: null,
      chatsFilter: null,
      NPSChart: {
        series: [],
        chartOptions: {
          labels: ["Promotor", "Neutro", "Detrator"],
          colors: ["#66C871", "#9CA3AF", "#EB4643", "#FF9F43", "#1E1E1E"],
          legend: {
            show: true,
            position: "bottom",
            fontSize: '11px'
          },
        },
      },
      CSATChart: {
        series: [],
        chartOptions: {
          labels: ["Satisfeito", "Neutro", "Insatisfeito"],
          colors: ["#66C871", "#9CA3AF", "#EB4643", "#FF9F43", "#1E1E1E"],
          legend: {
            show: true,
            position: "bottom",
            fontSize: '11px'
          },
        },
      },
      intentionsArray: [],
      selectAllIntentions: true,
      operatorsArray: [],
      selectAllOperators: true,
      tagsArray: [],
      npsScore: "0",
      csatScore: "0",
      noRecordsAutoMessageSurvey: false,
      // CHAT GPT SUGGESTION
      gptResumeFeedback: "",
    };
  },
  watch: {
    currentPage: function () {
      this.getApps();
    },
    dateFilter: function () {
      if (this.dateFilter && this.dateFilterRange) {
        this.dateFilterRange = null;
      }
      this.applyFilters();
    },
    dateFilterRange: function () {
      if (this.dateFilter && this.dateFilterRange) {
        this.dateFilter = null;
      }
      this.applyFilters();
    },
    tagsFilter: function (val, oldVal) {
      if (oldVal !== null) this.applyFilters();
    },
    chatsFilter: function (val, oldVal) {
      if (oldVal !== null) this.applyFilters();
    },
    categoryString: function () {
      this.applyFilters();
    },
    searchString: function () {
      this.applyFilters();
    },
    csatString() {
      this.applyFilters();
    },
    npsString() {
      this.applyFilters();
    },
    intentionString() {
      this.applyFilters();
    },
    operatorString() {
      this.applyFilters();
    },
  },
  computed: {
    currentAccId() {
      return this.$store.state.acc.current_acc.id;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.currentAccId === "60ad40a8cf24431d122b2433" ||
        this.currentAccId === "608c60d25e9671ec25384820" ||
        this.currentAccId === "5f5a4806e7a7bc318ae589cc" ||
        this.currentAccId === "64e7286b799c8d04217445ee" ||
        this.currentAccId === "626a9da67edc070332477a55" ||
        this.currentAccId === "626a9d947edc07e34c4776a1" ||
        this.currentAccId === "64f774f21bb62e5a0f0ae7be" ||
        this.currentAccId === "634f2828d73776a6886df536" ||
        this.currentAccId === "638ddbce63ef447b55b5f6b1" ||
        this.currentAccId === "63286decb79ebf3b3384367e" ||
        this.currentAccId === "6298bbf57d445a5dec90e060" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "623b367aadbb4d4aa6802372" ||
        this.currentAccId === "63a36fd60a079cd7b75de1ed" ||
        this.currentAccId === "63a36fe60a079c2e6d5de573" ||
        this.currentAccId === "647de082c17f5f6bc518bcca" ||
        this.currentAccId === "647de0c5f88ea8e8edbbd3dd" ||
        this.currentAccId === "647de0ddfdf35443e301ebcf" ||
        this.currentAccId === "647de0f4fdf354294f01ef36" ||
        this.currentAccId === "647de106f88ea8652cbbde82" ||
        this.currentAccId === "647de11ed44b0d4ad326c9d2" ||
        this.currentAccId === "647de131821e83a789e02735" ||
        this.currentAccId === "647de37afdf354c84502809b" ||
        this.currentAccId === "647de399d44b0d36682757c2" ||
        this.currentAccId === "647de3af7c43cdc9d2c25192" ||
        this.currentAccId === "647e12ed2e3fd754b5375ef1" ||
        this.currentAccId === "648c67528f79849e304b2205" ||
        this.currentAccId === "64a5870d3ef4914948a88910" ||
        this.currentAccId === "64a5871e997f109a046f4c4c" ||
        this.currentAccId === "6310a6c603f50d057e202d02" ||
        this.currentAccId === "6310a75f03f50d65af207d68" ||
        this.currentAccId === "63a36faa51d3cd17c1e400a3" ||
        this.currentAccId === "63ab38651b5aadfa16ab87b6" ||
        this.currentAccId === "63efc042b2d31059ad35d66e"
      );
    },
    hasDownloadBlocked() {
      return this.$store.state.acc.current_acc.blockDownloadExcel;
    },
  },
  methods: {
    applyFilters() {
      this.currentPage = 0;
      this.apps = [];
      this.nbTotalApps = 0;
      this.nbPages = 0;
      this.nbApps = 0;
      this.getApps();
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    getCategories() {
      this.categories = [
        { value: "", label: this.$t("All"), isRefined: false },
        { value: "NPS", label: "NPS", isRefined: false },
        { value: "CSAT", label: "CSAT", isRefined: false },
      ];
    },
    getApps() {
      this.$vs.loading();
      const filters = {};
      if (this.dateFilter) {
        filters.dateFilter = this.dateFilter;
        filters.dateFilterRange = null;
      }
      if (this.dateFilterRange) {
        filters.dateFilter = 7;
        filters.dateFilterRange = this.dateFilterRange;
      }
      if (this.intentionString) filters.intentionsFilter = this.intentionString;
      if (this.operatorString) filters.operatorsFilter = this.operatorString;
      if (!this.chatbotData.every((val) => this.chatsFilter.includes(val.id))) {
        filters.chatsFilter = Object.assign([], this.chatsFilter);
      }
      if (this.searchString.length > 0) filters.nameFilter = this.searchString;
      if (this.categoryString !== "") {
        filters.categoryFilter = this.categoryString;
        filters.categoryNps = this.npsString;
        filters.categoryCsat = this.csatString;
      }

      this.$http
        .get("/p/chat/survey/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filters,
            limit: this.limit,
            page: this.currentPage > 0 ? this.currentPage - 1 : 0,
          },
        })
        .then((response) => {
          const scoresNps = response.data.data.scoresNps;
          const scoresCsat = response.data.data.scoresCsat;

          this.apps = response.data.data.items;
          this.npsScore = response.data.data.totalScoreNps;
          this.csatScore = response.data.data.totalScoreCsat;

          if (this.categoryString === "NPS" || this.categoryString === "")
            this.updateNpsChart(scoresNps);
          if (this.categoryString === "CSAT" || this.categoryString === "")
            this.updateCsatChart(scoresCsat);

          this.nbTotalApps = response.data.data.count;
          this.nbPages = Math.ceil(response.data.data.count / this.limit);
          this.nbApps = response.data.data.items.length;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getChats() {
      this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.currentAccId,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          var ArrayChats = [];
          for (var i = 0; i < response.data.data.length; i++) {
            ArrayChats.push({
              id: response.data.data[i]._id,
              label: response.data.data[i].name,
            });
          }
          this.chatbotData = ArrayChats;
          this.chatsFilter = ArrayChats.map((chat) => chat.id);
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    formatIntentionName(intention) {
      if (intention.length === 0) intention = this.$t("Others");
      return `${intention[0].toUpperCase()}${intention.slice(1).toLowerCase()}`;
    },
    filterSurveys() {
      if (this.timerSearchString) {
        clearTimeout(this.timerSearchString);
        this.timerSearchString = null;
      }
      this.timerSearchString = setTimeout(() => {
        this.searchString = this.searchStringLocal;
      }, 800);
    },
    updateNpsChart(scoresNps) {
      const npsSeries = [];

      npsSeries.push(
        scoresNps.find((i) => i._id === 9)
          ? scoresNps.find((i) => i._id === 9).count
          : 0
      );
      npsSeries.push(
        scoresNps.find((i) => i._id === 7)
          ? scoresNps.find((i) => i._id === 7).count
          : 0
      );
      npsSeries.push(
        scoresNps.find((i) => i._id === 0)
          ? scoresNps.find((i) => i._id === 0).count
          : 0
      );
      this.$refs.npsChart.updateSeries(npsSeries);
    },
    updateCsatChart(scoresCsat) {
      const csatSeries = [];

      csatSeries.push(
        scoresCsat.find((i) => i._id === 2)
          ? scoresCsat.find((i) => i._id === 2).count
          : 0
      );
      csatSeries.push(
        scoresCsat.find((i) => i._id === 1)
          ? scoresCsat.find((i) => i._id === 1).count
          : 0
      );
      csatSeries.push(
        scoresCsat.find((i) => i._id === 0)
          ? scoresCsat.find((i) => i._id === 0).count
          : 0
      );
      this.$refs.csatChart.updateSeries(csatSeries);
    },
    sendToInbox(conversationId) {
      window.analytics.track(
        "Checar PS",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$router.push({
        name: "inbox",
        query: { conversation: conversationId },
      });
    },
    async getFeedbackHistoric() {
      const filters = {};
      let endDate = new Date();
      let startDate = new Date();
      let data = "";

      startDate.setDate(endDate.getDate() - 90);

      filters.dateFilterRange = [startDate, endDate];
      filters.dateFilter = 7;

      await this.$http
        .get("/p/chat/survey/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filters,
            limit: null,
          },
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.items
          ) {
            let texts = 0;
            response.data.data.items.forEach((item) => {
              if (texts >= 8000) {
                return;
              }

              texts += item.message.length;
              data += item.message + "\n";
            });
          }
        });

      return [{ role: "user", content: data }];
    },
    async getAllFeedbackMonth() {
      let data = {};
      const filters = {};
      if (this.dateFilter) {
        filters.dateFilter = this.dateFilter;
        filters.dateFilterRange = null;
      } else {
        filters.dateFilter = 8;
      }
      if (this.dateFilterRange) {
        filters.dateFilter = 7;
        filters.dateFilterRange = this.dateFilterRange;
      }

      await this.$http
        .get("/p/chat/survey/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            filters,
            limit: null,
          },
        })
        .then((response) => {
          data = response.data.data.items;
        });
      return data;
    },
    async generateGptResumeFeedback() {
      window.analytics.track(
        "Resumir feedback",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      const payload = await this.getFeedbackHistoric();

      try {
        const generatedMessage = await this.$http.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            type: "feedbackResume",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.gptResumeFeedback = generatedMessage.data.data;
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
    treatMessageText(text) {
      if (!text) return "";

      if (text) {
        text = text.replace(
          /[&<>'"]/g,
          (tag) =>
            ({
              "&": "&amp;",
              "<": "&lt;",
              ">": "&gt;",
              "'": "&#39;",
              '"': "&quot;",
            }[tag] || tag)
        );

        let newText = text;

        const links = text.match(/(https?:\/\/|www.).[^\s]+/g);
        if (links) {
          links.forEach((link) => {
            newText = newText.replace(
              link,
              link.includes("/csat/") || link.includes("/nps/")
                ? ` <i>${this.$t("LinkRemovedSecured")}</i>`
                : ` <a href="${
                    !link.includes("http") ? `//${link}` : link
                  }" target="_blank">${link}</a>`
            );
          });
        }

        const regex = /^\*/;
        const result = regex.test(newText);
        if (result) {
          newText = newText.replace(/^\*/, "<b>");
          newText = newText.replace(/\*:\n/g, "</b>:<br>");
        }
        return `${newText.trim()}`;
      }
    },
    async downloadCSV() {
      this.$vs.loading();
      // June track
      window.analytics.track(
        "Download Feedbacks",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      const XLSX = xlsx,
        workbook = XLSX.utils.book_new(),
        arrayXlsx = [];

      const headers = {
        conversationId: "ID da conversa",
        updatedAt: "Data",
        chatName: "Chatbot",
        contactName: "Nome do contato",
        intentions: "Intenção",
        closingOperatorName: "Operador avaliado",
        type: "CSAT ou NPS",
        origin: "Origem",
        channel: "Canal",
        score: "Nota",
        message: "Conteúdo da avaliação",
      };
      const payload = await this.getAllFeedbackMonth();
      if (payload) {
        payload.forEach((object) => {
          let scoreText;
          if (object.type === "CSAT") {
            if (object.score === 0) scoreText = "Insatisfeito";
            if (object.score === 1) scoreText = "Neutro";
            if (object.score === 2) scoreText = "Satisfeito";
          }
          const data = {
            [headers.conversationId]: object.conversationId,
            [headers.updatedAt]: `${new Date(
              object.updatedAt
            ).toLocaleDateString()} ${this.$t("atTime")} ${new Date(
              object.updatedAt
            ).toLocaleTimeString()}`,
            [headers.chatName]: object.chatName,
            [headers.contactName]: object.contactName,
            [headers.intentions]: object.intentions
              .map((item) => item)
              .join(","),
            [headers.closingOperatorName]: object.closingOperatorName,
            [headers.type]: object.type,
            [headers.origin]: object.origin,
            [headers.channel]: object.channel,
            [headers.score]: scoreText || object.score,
            [headers.message]: object.message || "",
          };
          arrayXlsx.push(data);
        });
        const worksheet = XLSX.utils.json_to_sheet(arrayXlsx);
        XLSX.utils.book_append_sheet(workbook, worksheet, "report");
        XLSX.writeFile(workbook, "feedback-history.xlsx");
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
      } else {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("UnexpectedError"),
          text: "Não foram encontrados feedbacks para o período selecionado. Por favor, atualize o filtro",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }
    },
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  mounted() {
    this.getApps();
    this.getChats();
    this.getCategories();

    this.$http
      .get(`/p/chat/inbox/${this.currentAccId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        const data = response.data.data.inbox;
        this.intentionsArray = ["Todos", ...data.intentions, "Outros"];
        this.intentionString = "Todos";

        this.tagsArray = ["Nenhuma", ...data.tags] || ["Nenhuma"];
        this.tagsFilter = Object.assign([], this.tagsArray);
      });

    this.$http
      .get(`/p/chat/inbox/operators/list`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        params: {
          acc: this.currentAccId,
        },
      })
      .then((response) => {
        // Get operator_id para o filtro
        this.operatorsArray = [
          { name: "Todos", operatorData: { _id: "Todos" } },
          ...response.data.data,
        ];

        this.operatorString = "Todos";
      });

    this.$http
      .get("/p/chat/inbox/autoMessages", {
        params: { acc: this.$store.state.acc.current_acc.id },
      })
      .then((response) => {
        const nps = response.data.data.find(
          (autoMessage) => autoMessage.type === "nps_survey"
        );
        const csat = response.data.data.find(
          (autoMessage) => autoMessage.type === "csat_survey"
        );

        (nps && nps.status === 0) || (csat && csat.status === 0)
          ? (this.noRecordsAutoMessageSurvey = true)
          : (this.noRecordsAutoMessageSurvey = false);
      });
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style lang="scss" scoped>
.vs-sidebar--items {
  display: table !important;
}
.con-vs-dialog {
  z-index: 2147483647 !important;
}

.sidebar-spacer-right-with-margin {
  width: calc(100% - 260px - 2.2rem);
  margin-right: calc(260px + 2.2rem);
}

#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 100px;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }
    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

.mx-datepicker {
  width: -webkit-fill-available;
}

.mx-datepicker-main {
  z-index: 40000;
}

.createSummary {
  background-image: linear-gradient(
      0deg,
      rgba(255, 210, 0, 0.21),
      rgba(255, 210, 0, 0.21)
    ),
    linear-gradient(0deg, #ffd200, #ffd200);
  background-size: auto, 5px 100%;
  background-repeat: repeat, no-repeat;

  button {
    background: rgba(255, 252, 238, 1) !important;

    &:hover {
      background: rgba(255, 210, 0, 1) !important;
    }
  }
}
.summaryBox {
  background: linear-gradient(
      0deg,
      rgba(255, 210, 0, 0.0666667),
      rgba(255, 210, 0, 0.0666667)
    ),
    #ffffff;
  border: 1px solid #ffd200;

  .tag-summary {
    background: rgba(255, 210, 0, 1);
    //padding: 5px 10px 5px 10px;
  }
}
</style>
